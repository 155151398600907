.PlaceCleaningList {
  border-bottom: 1px solid #919191;
  padding-bottom: 20px;
}

.PlaceCleaningList__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.PlaceCleaningList__item {
padding-top: 2px;
padding-bottom: 2px;
/* cursor: pointer; */
}
